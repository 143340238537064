<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Usuario - Nuevo</strong>
          </CCardHeader>
          <CCardBody>
            <b-form  @submit.prevent="AddUser">
              <b-row>
                <b-col md="3"></b-col>
                <b-col md="6">
                  <b-form-group label="Tipo de Usuario :" required>
                    <select
                      ref="id_user_type"
                      class="form-control"
                      v-model="user.id_user_type"
                    >
                      <option value="">-- Seleccione --</option>
                      <option
                        v-for="item of user_type"
                        :key="item.id_user_type"
                        :value="item.id_user_type"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <small
                      v-if="errors.id_user_type"
                      class="form-text text-danger"
                      >Seleccione un tipo de usuario</small
                    >
                  </b-form-group>
                </b-col>
                <b-col md="3"></b-col>

                <b-col md="3"></b-col>
                <b-col md="3">
                  <b-form-group label="Nombres :" required>
                    <b-form-input
                      type="text"
                      ref="name"
                      v-model="user.name"
                      placeholder="Ingrese su nombre"
                    ></b-form-input>
                    <small v-if="errors.name" class="form-text text-danger"
                      >Ingrese un nombre de usuario</small
                    >
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group label="Apellido :" required>
                    <b-form-input
                      type="text"
                      ref="last_name"
                      v-model="user.last_name"
                      placeholder="Ingrese su apellido"
                    ></b-form-input>
                    <small v-if="errors.last_name" class="form-text text-danger"
                      >Ingrese un apellido de usuario</small
                    >
                  </b-form-group>
                </b-col>
                <b-col md="3"></b-col>

                <b-col md="3"></b-col>
                <b-col md="6">
                  <b-form-group label="Telefono :" required>
                    <b-form-input
                      type="text"
                      ref="phone"
                      v-model="user.phone"
                      placeholder="Ingrese su Telefono"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="3"></b-col>

                <b-col md="3"></b-col>
                <b-col md="6">
                  <b-form-group label="Email :" required>
                    <b-form-input
                      type="email"
                      ref="email"
                      v-model="user.email"
                      placeholder="Ingrese su email"
                    ></b-form-input>
                    <small v-if="errors.email" class="form-text text-danger"
                      >Ingrese un email de usuario</small
                    >
                  </b-form-group>
                </b-col>
                <b-col md="3"></b-col>

                <b-col md="3"></b-col>
                <b-col md="6">
                  <b-form-group label="Password :" label-for="input-1">
                    <b-form-input
                      type="password"
                      ref="password"
                      v-model="user.password"
                    ></b-form-input>
                    <small v-if="errors.password" class="form-text text-danger"
                      >Ingrese un password de usuario</small
                    >
                  </b-form-group>
                </b-col>
                <b-col md="3"></b-col>

                <b-col md="3"></b-col>
                <b-col md="6">
                  <b-form-group label="Estado :" label-for="input-1">
                    <select
                      ref="state"
                      v-model="user.state"
                      class="form-control"
                    >
                      <option value="1">Activo</option>
                      <option value="0">Inactivo</option>
                    </select>
                  </b-form-group>
                </b-col>
                <b-col md="3"></b-col>

                <b-col md="3"></b-col>
           
                <b-col md="6">
                  <b-button
                    type="submit"
                    class="btn form-control  btn-primary"
                    >GUARDAR</b-button>
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";

export default {
  name: "UsuarioAdd",
  data() {
    return {
      module:'User',
      user: {
        id_user_type: "",
        name: "",
        last_name: "",
        email: "",
        password: "",
        phone: "",
        state: 1,
      },
      user_type: [],

      //errors
      errors: {
        id_user_type: false,
        name: false,
        last_name: false,
        email: false,
        password: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ListUserType();
  },
  methods: {
    ListUserType,
    AddUser,
    Validate,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

//listar usuario
function ListUserType() {
  let me = this;
  let url = this.url_base + "usertype-list-active";

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.user_type = response.data.result;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function AddUser() {
  // validacion de campos obligatorios
  this.Validate();
  if (this.validate) {
    return false;
  }

  let me = this;
  let url = this.url_base + "user/add";
  let data = {
    id_user_type: this.user.id_user_type,
    name: this.user.name,
    last_name: this.user.last_name,
    phone: this.user.phone,
    email: this.user.email,
    password: this.user.password,
    state: this.user.state,
  };

  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {
      "Content-Type": "application/json",
      token: this.token,
      module: this.module,
      role: 2,
    },
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.user.id_user_type = "";
        me.user.name = "";
        me.user.last_name = "";
        me.user.phone = "";
        me.user.email = "";
        me.user.password = "";
        me.user.state = 1;
        Swal.fire("Usuario", "Se ha creado el nuevo usuario", "success");
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function Validate() {
  this.errors.id_user_type = this.user.id_user_type.length == 0 ? true : false;
  this.errors.name = this.user.name.length == 0 ? true : false;
  this.errors.last_name = this.user.last_name.length == 0 ? true : false;
  this.errors.email = this.user.email.length == 0 ? true : false;
  this.errors.password = this.user.password.length == 0 ? true : false;

  this.validate = this.errors.id_user_type == true ? true : false;
  this.validate = this.errors.name == true ? true : false;
  this.validate = this.errors.last_name == true ? true : false;
  this.validate = this.errors.email == true ? true : false;
  this.validate = this.errors.password == true ? true : false;
}
</script>
